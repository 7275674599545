<template>
  <div class="space-y-4">
    <div class="px-6 py-4 flex justify-between bg-white border-b border-neutral-50">
      <div class="text-bb-text-default">
        <h3 class="h3">Competitor Monitoring</h3>
        <p class="text-sm">
          Get insights on competitors, track rankings, and stay updated on changes in search results
        </p>
      </div>
      <div class="flex items-center divide-x divide-neutral-100">
        <div class="px-6">
          <ic-list
            :size="20"
            class="text-bb-text-default hover:text-bb-brand-purple duration-300 ease-linear cursor-pointer"
            @click="$router.push({ name: 'all-groups' })"
          />
        </div>
        <div class="px-6">
          <ic-solid-cog
            :size="20"
            class="text-bb-text-default hover:text-bb-brand-purple duration-300 ease-linear cursor-pointer"
            @click="showSettingsModal"
          />
        </div>
      </div>
    </div>
    <div
      v-if="selectedMonitoringGroup.id"
      class="px-6"
    >
      <monitoring-group-card
        read-only
        right-button
        :monitoring-group="selectedMonitoringGroup"
        class="shadow-bb-shadow"
        @open-modal="switchMonitoringGroup"
      >
        <template #right-icon>
          <ic-swap />
        </template>
      </monitoring-group-card>
    </div>

    <div class="px-6">
      <page-tabs
        :tabs="pageTabs"
        :selected-tab="'overview'"
      >
        <div
          v-for="tab in pageTabs"
          :key="tab.value"
          class="cursor-pointer z-10 duration-300 ease-linear"
          style="border-bottom-width: 3px"
          :class="
            $route.name === tab.value
              ? 'border-bb-brand-purple text-bb-brand-purple'
              : 'text-bb-text-secondary border-neutral-0 hover:border-neutral-100'
          "
        >
          <div
            class="px-6 pb-2 flex gap-2 items-center"
            @click="selectTab(tab.value)"
          >
            <p>
              {{ tab.label }}
            </p>
            <div
              v-if="tab.value === 'infringements-detector-v2' && totalInfringements > 0"
              class="bg-bb-red-error text-xs text-white font-bold h-6 w-6 rounded-md flex items-center justify-center"
            >
              <p>{{ totalInfringements }}</p>
            </div>
          </div>
        </div>
      </page-tabs>
    </div>
  </div>
</template>

<script>
import IcList from '@/components/icon/ic-list.vue'
import IcSolidCog from '@/components/icon/brightbid/ic-solid-cog.vue'
import IcSwap from '@/components/icon/brightbid/ic-swap.vue'
import MonitoringGroupCard from '@/views/site/search/competitor_monitoring_v2/components/MonitoringGroupCard.vue'
import PageTabs from '@/components/shared/PageTabs.vue'

export default {
  name: 'MainHeader',
  components: { PageTabs, MonitoringGroupCard, IcSolidCog, IcList, IcSwap },
  props: {
    selectedMonitoringGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pageTabs: [
        { label: 'Overview', value: 'overview-v2' },
        { label: 'Infringements Detector', value: 'infringements-detector-v2' },
        { label: 'Competitors List', value: 'all-competitors-v2' },
      ],
      totalInfringements: 10,
    }
  },
  methods: {
    showSettingsModal() {
      this.$emit('show-selected-monitoring-settings')
    },
    switchMonitoringGroup() {
      this.$emit('show-switch-monitoring-modal')
    },
    async selectTab(tab) {
      this.$router.push({ name: tab })
    },
  },
}
</script>

<style scoped lang="scss"></style>
