<template>
  <div class="bg-white">
    <div
      v-if="hasMonitoringGroup && !noDefaultHeader"
      class="bg-neutral-0 shadow-lg"
    >
      <!-- CMS Header -->
      <main-header
        :selected-monitoring-group="computedSelectedMonitoringGroup"
        @show-switch-monitoring-modal="showSwitchMonitoringModal"
        @show-selected-monitoring-settings="showSelectedMonitoringSettings"
      />
    </div>
    <RouterView />

    <!--LOADER - Whole Page-->
    <div
      v-if="isPageLoading"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>
    <!-- Modals -->
    <bb-base-modal
      v-if="visibleModal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="closeModal"
    >
      <template #container>
        <monitoring-groups
          v-if="visibleModal === 'monitoring-groups'"
          :monitoring-groups="computedMonitoringGroups"
          :selected-monitoring-group="computedSelectedMonitoringGroup"
          @select-monitoring-group="selectMonitoringGroup"
          @close-modal="closeModal"
        />
        <selected-monitoring-settings
          v-if="visibleModal === 'selected-monitoring-settings'"
          @close-modal="closeModal"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import MainHeader from '@/views/site/search/competitor_monitoring_v2/components/MainHeader.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import MonitoringGroups from '@/views/site/search/competitor_monitoring_v2/components/modals/MonitoringGroups.vue'
import SelectedMonitoringSettings from './components/modals/SelectedMonitoringSettings.vue'

const DEFAULT_CREDIT_LIMIT = 100000

export default {
  name: 'CompetitorMonitoringV2',
  components: {
    BrightbidLoader,
    MainHeader,
    BbBaseModal,
    MonitoringGroups,
    SelectedMonitoringSettings,
  },
  data() {
    return {
      isInitializing: true,
      isPageLoading: false,
      visibleModal: null,
      modalConfig: {
        width: '',
        height: '',
      },
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoringV2', ['monitoringGroups', 'selectedMonitoringGroup']),
    ...mapState('search', ['campaignsAll']),
    currentRoute() {
      return this.$route.name
    },
    noDefaultHeader() {
      const routes = ['onboarding-v2', 'all-groups']
      return routes.includes(this.currentRoute)
    },
    computedMonitoringGroups() {
      return this.monitoringGroups.map(group => {
        const campaign = this.campaignsAll.find(campaign => campaign.campaign_id === group?.googleCampaignId)
        return {
          ...group,
          campaignName: campaign?.name ?? null,
        }
      })
    },
    computedSelectedMonitoringGroup() {
      return this.computedMonitoringGroups.find(group => group.id === this.selectedMonitoringGroup?.id)
    },
    hasMonitoringGroup() {
      return this.monitoringGroups.length > 0
    },
  },
  watch: {
    currentRoute: {
      handler(route) {
        if (this.isPageLoading) return
        this.handleRoute(route)
      },
      immediate: true,
    },
  },
  async mounted() {
    this.isPageLoading = true
    await this.loadSearchCampaignsAll({ siteId: this.selectedSite.value, exclusion: 'DISPLAY' })
    const cmsSiteReady = await this.cmsSiteInitialization()
    this.isInitializing = false

    if (!cmsSiteReady.isReady) {
      // TODO: what to do if not yet ready?
      console.log(cmsSiteReady.message)
      console.log(cmsSiteReady.field)
    }

    // This will be used if data is loaded for the first time.
    // The watcher will be followed after this.
    this.handleRoute(this.currentRoute)

    this.isPageLoading = false
  },
  methods: {
    ...mapActions('toast', ['loadToast']),
    ...mapActions('competitorMonitoringV2', [
      'loadMonitoringGroups',
      'loadCmsSite',
      'loadOrganizationCredit',
      'loadSelectedMonitoringGroup',
    ]),
    ...mapActions('search', ['loadSearchCampaignsAll']),
    handleRoute(route) {
      // onboarding route
      if (route === 'onboarding-v2') {
        this.$router.push({ name: 'onboarding-v2' })
      }
      // no selected monitoring group but has monitoring groups
      else if (this.selectedMonitoringGroup === null && this.hasMonitoringGroup) {
        this.$router.push({ name: route === 'competitor-monitoring-v2' ? 'all-groups' : route })
      }
      // has both selected monitoring group and monitoring groups
      else if (this.selectedMonitoringGroup && this.hasMonitoringGroup) {
        this.$router.push({ name: route === 'competitor-monitoring-v2' ? 'overview-v2' : route })
      }
      // no monitoring group and not yet done initializing
      else if (!this.hasMonitoringGroup && !this.isInitializing) {
        this.$router.push({ name: 'onboarding-v2' })
      }
    },
    async cmsSiteInitialization() {
      /**
       * 1. Check if the site has a URL
       * 2. Get campaigns, CMS site, and site credit
       * 3. Create site if it doesn't exist
       * 4. Set organization credit limit if it doesn't exist
       */

      // Check if the site has a URL
      if (this.selectedSite.url === '' || !Object.prototype.hasOwnProperty.call(this.selectedSite, 'url')) {
        await this.loadToast({
          title: 'Error',
          message: "Site doesn't have a URL. Please add a URL to the site.",
          type: 'error',
        })

        // handle this properly
        return {
          isReady: false,
          message: 'Site does not have a URL. Please add a URL to the site.',
          field: 'credit_limit',
        }
      }

      // Get campaigns, CMS site, and site credit
      const [cmsSite, siteCredit] = await Promise.all([
        this.loadCmsSite(this.selectedSite.value),
        this.loadOrganizationCredit(this.selectedSite.site_organization_id),
        this.loadMonitoringGroups(this.selectedSite.value),
      ])

      if (!cmsSite) {
        // Create site if it doesn't exist
        const isSiteCreated = await this.createSite()
        if (!isSiteCreated) {
          return {
            isReady: false,
            message: 'Failed to create CMS site.',
            field: 'credit_limit',
          }
        }
      }

      if (!siteCredit) {
        // Set organization credit limit if it doesn't exist
        const createdCredit = await this.setOrganizationCreditLimit()
        if (!createdCredit) {
          return {
            isReady: false,
            message: 'Failed to set organization credit limit.',
            field: 'credit_limit',
          }
        }
      }

      // success
      return {
        isReady: true,
        message: 'Successfully initialized CMS site.',
        field: '',
      }
    },

    async createSite() {
      try {
        const payload = {
          id: this.selectedSite.value,
          url: this.selectedSite.url,
        }
        const { data } = await this.$http.post(`/cms/site`, payload)
        return data
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to create site. Please try again later.',
          type: 'error',
        })
        return null
      }
    },
    async setOrganizationCreditLimit() {
      try {
        const { data } = await this.$http.post(
          `/common/organization/${this.selectedSite.site_organization_id}/cms-credit/limit`,
          {
            credit_limit: DEFAULT_CREDIT_LIMIT,
          },
        )
        return data
      } catch (e) {
        if (e.response.data === 'Cms credit limit already exists for this organization.') {
          return true
        }
        await this.loadToast({
          title: 'Error',
          message: 'Failed to set organization credit limit. Please try again later.',
          type: 'error',
        })
        return null
      }
    },
    showSwitchMonitoringModal() {
      this.visibleModal = 'monitoring-groups'
      this.modalConfig = {
        width: '80vw',
        height: '80vh',
      }
    },
    showSelectedMonitoringSettings() {
      this.visibleModal = 'selected-monitoring-settings'
      this.modalConfig = {
        width: '800px',
        height: '720px',
      }
    },
    async selectMonitoringGroup(monitoringGroup) {
      await this.loadSelectedMonitoringGroup(monitoringGroup)
      this.closeModal()
    },
    closeModal() {
      this.visibleModal = null
    },
  },
}
</script>

<style scoped lang="scss"></style>
